<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <!-- <el-config-provider :locale="messages[locale]"> -->
  <!-- <RouterView /> -->
  <router-view />
  <!-- </el-config-provider> -->
</template>
<script setup>
// import { ElConfigProvider } from "element-plus";
// import { useI18n } from "vue-i18n";
// const { locale, messages } = useI18n();
import { onMounted } from "vue";
import { isMobile } from "@/utils/isMobile";
import { useRouter } from "vue-router";
// import store from "@/store";
const router = useRouter();

onMounted(() => {
  // alert(window.location.href);
  // alert(window.location.search);
  // alert(window.location.href.split("?")[0].split("#")[1]);
  // alert(localStorage.getItem("token"));
  // alert(store.state.token);
  // console.log(router, router.currentRoute.value.name, "router");
  if (window.location.href.split("?")[0].split("#")[1] === "/kpi/employee/sign/phoneCopy") {
    return;
  } else if (isMobile()) {
    router.replace("/phone/home");
  }
});
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
input,
textarea {
  resize: none; /* 禁止文本域调整大小 */
  font-size: inherit; /* 继承字体大小 */
  font-family: inherit; /* 继承字体家族 */
  line-height: inherit; /* 继承行高 */
  -webkit-appearance: none; /* 移除默认样式 */
  -webkit-tap-highlight-color: transparent; /* 移除点击高亮 */
}
</style>
