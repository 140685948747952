export default {
  home: "Home",
  kpi: "KPI Manager",
  kpiScore: "KPI Score",
  kpiAppraisal: "Performance Appraisal",
  deptAssessment: "Dept Assessment",
  event: "Event Manager",
  eventPublish: "Event Publish",
  eventHistory: "Event History",
  value: "Value Manager",
  valueList: "Value List",
  valueDeptCommon: "Value Dept Approval",
};
